import * as React from 'react'
import { PageLayout } from '../layouts/PageLayout'
import { Title } from '../components/Title'

const Error404: React.FC = () => {
  return (
    <PageLayout>
      <Title title="Not found" />
      <h1>404 Page</h1>
    </PageLayout>
  )
}

export default Error404
